import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class CaseStudy extends React.Component {


  render() {

    return (
      <section className="banner casestudy pt-0">
        <div className='casestudy-bg'>
        <div className="container">
            <h2 className='heading-h2 text-center'>Our Recent <span className='bluecolor'>successful Work</span></h2>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <StaticImage 
                    src="https://coinsclone.mo.cloudinary.net/images/remitano-new/manilla-logo.png"
                    alt="manilla logo"
                    width={316}
                    className='mb-4'
                />
                <ul>
                    <li>A startup from Nigeria, Manilla used our Remitano Clone Script to build a popular <b>P2P Crypto Exchange</b> with a bug-free Escrow system. </li>
                    <li>The platform successfully reached globally around 44 nations with <b>4.2 Million Active Users</b> and around 197 million dollar transactions.</li>
                    <li>Manilla managed to gather around 5,00,000 users in just six months and made impressive progress by gaining wholesome trust. </li>
                    <li>Additionally, Manilla promises a 24/7 trading service and can handle up to 5,000 concurrent users which speaks volumes of its supremacy.</li>
                </ul>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/remitano-new/manilla-casestudy.png"
                alt="Manilla Casestudy"
                width={780}
              />
            </div>
          </div>
          <div className='text-center'>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo of our Remitano clone script </a>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default CaseStudy