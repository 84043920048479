import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class Addon extends Component {


  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Added revenue modules</span> in our</span>
            Remitano Clone Script</h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                Swap
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                Investment
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                Site API
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                Invite & Earn
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                Liquidity API Integration
                </li>
              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Swap
                      </h4>
                      <p className="pharagraph">Our P2P exchange script allows your users to swap one Cryptocurrency for another in a similar process to trading. For swapping, you can charge the users a small amount of commission. 
                      </p>
                      <div className="text-left mt-4">
                        <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="341px" height="346px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/swap.png" alt="Swap image1" />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Investment
                      </h4>
                      <p className="pharagraph">Investing in Cryptocurrencies is one of the best ways to earn profits and users have always considered buying digital assets as a better investment. More and more investors in Bitcoin will increase revenue for you.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="289px" height="266px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/investment.png" alt="Investment image1" />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Site API
                      </h4>
                      <p className="pharagraph">Our Remitano clone software provides two types of APIs; Rest API and WebSocket API. Rest API provides the architectural design of how the core exchange’s interlinked resources should look. Websocket APIs, on the other hand, are generally used to provide real-time updates which can include live prices of Cryptocurrencies. 
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="488px" height="325px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/site-api.png" alt="Site API image1"  />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Invite & Earn</h4> 
                      <p className="pharagraph">Our Remitano Clone comes up with the invite and earn module which helps to enhance the user numbers significantly. The exchange software can be set in a way that your users can activate the referral code and trade for free.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="301px" height="301px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/invite-earn.png" alt="Invite & Earn image1" />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Liquidity API Integration</h4>
                      <p className="pharagraph">With our exclusive P2P exchange software, you can easily integrate with top exchanges using an API connection. This factor is quite helpful in maintaining liquidity in the clone platform and increasing revenue overall. 
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="282px" height="273px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/liquidity-api-integration.png" alt="Liquidity API Integration image1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon