import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis py-0 mb-0">
        <div className='gray-bg transp'>
      <div className="container">
        <div className="shadoww">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2">Remitano Clone Script - <span className='bluecolor'>An Overview</span></h2>
            </div>
          </div>

          <div className="text-center">
            <p className="pharagraph" >Remitano Clone script is a pre-fabricated software that includes the features and functionalities of a popular P2P exchange platform like Remitano. Our Remitano-like exchange comprises best-in-class features with a large scope of customization. With our exchange app, users can buy, sell or trade Cryptocurrencies directly in an efficient manner without a third party in between through an escrow-based system. Our script replicates the entire functions available in the Remitano platform along with a packed security channel.
            </p>
            <p className="pharagraph mb-0">Join hands with us to develop a top-notch P2P-based Cryptocurrency Exchange platform. Our blockchain experts have used modern and updated cutting-edge technologies to make sure that the Remitano exchange script we provide has instilled engagement-building plug-ins. Launch a cracking peer-to-peer Crypto trading exchange instantly with the help of our white-label Crypto exchange software and make jaw-dropping revenue. Witness your users having a terrific experience with our mind-blowing dashboard panel that comes with our Remitano exchange software!
            </p>
          </div>
        </div>
      </div>
      </div>
    </section>
    )
  }
}

export default Whatis