import React from 'react'
import Slider from "react-slick";
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'



class Banner extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">Remitano</span> Clone Script</h1>
                <p className="pharagraph">Start a profitable peer-to-peer Crypto trading platform using our multi-tested Remitano Clone script. Our pre-made software has been designed in a way that helps entrepreneurs to reach great heights in a short span.
                </p>
                <ButtonStartfree />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
              <div className="screen">
                <img width="876px" height="501px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/header-with-lap-mockup.png" alt="Remitano Clone Laptop Front View image1" />
                <div className="outder">
                  <Slider {...settings}>
                    <img width="644px" height="402px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/remitano-clone-script.png" alt="Remitano Clone Script Software image1" />
                    <img width="644px" height="402px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/remitano-clone.png" alt="Remitano Clone Script image1" />
                    <img width="644px" height="402px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/remitano.png" alt="Remitano Clone image1" />
                  </Slider>

                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    )
  }
}

export default Banner