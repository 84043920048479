import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Mandatory Features</span> of </span> Our Remitano Clone Script</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Post BUY/SELL Advertisements</h4>
                <p className="pharagraph">With our post-buy or Sell option, your users can create advertisements quite conveniently for trading Bitcoin in our bug-free Remitano exchange script. 
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="562px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/post-buy-sell-advertisements.png" alt="Post BUY/SELL Advertisements image1" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="594px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/escrowed-trade.png" alt="Escrowed Trade image1" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Escrowed Trade
                </h4>
                <p className="pharagraph">The cryptocurrencies that are about to be traded from the seller to the buyer get locked on Remitano’s clone script escrow service. Once the buyer transfers the fiat money, our software releases the equivalent amount of Bitcoin to the user after verification
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-left" >
                <h4 className="heading-h4">Endless fiat payment options
                </h4>
                <p className="pharagraph">Our Remitano-like exchange app has a number of payment options for users including ADV cash, International wire transfer, Credit card, Moneygram, etc.,
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="568px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/endless-fiat-payment-options.png" alt="Endless fiat payment options image1" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="571px" height="314px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/kyc-verification.png" alt="KYC Verification image1" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">KYC Verification
                </h4>
                <p className="pharagraph">KYC or Know Your Customer feature helps to verify a person’s identity details including full name, date of birth, country of origin, and country of residence. Users have to complete KYC Verification mandatorily to enter your platform which ensures high security. 
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Advanced chart tools</h4>
                <p className="pharagraph">A modern way to speculate which Cryptocurrencies are peaking is the Advanced chart tools feature. With this feature in our script, users can study and analyze prices before trading Bitcoin to make profits.
                </p>
                <div className="text-left ">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="584px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/advanced-chart-tools.png" alt="Advanced chart tools image1" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures