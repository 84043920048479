import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class OurAndroid extends React.Component {
  state = {
    modal: false,
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  render() {
    return (
      <section className="android pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left leftpad">
            <h3 className="heading-h2">
              <span className="heading-h3">Remitano Clone App for</span> <span className="bluecolor">IOS & Android</span>
            </h3>
            {/* <p className="pharagraph head">Launch a user-friendly and eye-catching cryptocurrency trading mobile app like Remitano
            </p> */}
          </div>

          <div className="row table-content mobilewhitebox orderflex">
            <div className="col-md-12 col-lg-7 order2 mb-0">
              <p className="pharagraph">
                Our Clone services do not just stop with exchange software. We
                help startups to build apps similar to Remitano in an advanced
                way. Just like our Crypto exchange software, our Remitano clone
                app is feature-rich and customizable with an incredible user
                interface. Additionally, the app we provide is compatible with
                Android and IOS and hence, a wide variety of audiences is
                expected to enter your app like Remitano. We have created a
                mobile app like Remitano for Crypto users with all the premium
                options for you to stay on top of the market constantly.
              </p>
              <p className="pharagraph">
                Besides, the importance of an app in the current generation is
                vast and has significant benefits. Thankfully, our Remitano
                Clone app has satisfied most of the demands in the present era
                with its super-effective user and admin dashboard. As a result,
                startups can be assured of a successful business by inheriting
                our app identical to Remitano. With more users active on
                smartphones, your P2P Crypto exchange app like Remitano can be a
                dream place for trading. Our quality developers and testing team
                together have worked on providing a unique experience for
                traders and making your app stand out.
              </p>
              <div className="site-modal mobile-hide">
                <ButtonComponent />
              </div>
            </div>
            <div className="col-md-12 col-lg-5 mobileappicon text-center order1">
              <img
                width="530px"
                height="650px"
                src="https://coinsclone.mo.cloudinary.net/images/remitano/remitano-clone-app-development.png"
                alt="Remitano Clone App Development image1"
              />
            </div>
          </div>

          <div className="inner-container">
            <div className="platform">
              <div className="t-black plat-dev">Platforms Developed</div>
              <div className="playstoreicon">
                <div className="play">
                  <img
                    src="https://coinsclone.mo.cloudinary.net/images/android.svg"
                    alt="Android"
                  />
                  <span>Android</span>{" "}
                </div>
                <div className="play">
                  <img
                    src="https://coinsclone.mo.cloudinary.net/images/apple.svg"
                    alt="Apple"
                  />
                  <span>ios</span>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="site-modal mobile-on">
            <ButtonComponent />
          </div>
        </div>
      </section>
    )
  }
}

export default OurAndroid
