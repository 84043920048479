import React from 'react'



class BenifitsOf extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="lucrative pt-100 mb-0">
        <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
        <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Benefits of using</span> our</span>
            Remitano Clone Script</h3>
          <p className="pharagraph">Our script has a number of business benefits for entrepreneurs looking to make a mark in the industry. Acquire our finest Remitano clone to get all the exclusive benefits that we provide at an affordable cost.</p>
          </div>
          <div className="factor">
            <div className="revenue">
              <p>High scalability</p>
              <p>Budget-friendly cost</p>
              <p>No technical expertise or knowledge required</p>
            </div>
            <div className="revenue">
              <p>Instant deployment</p>
              <p>A first-class experience for users</p>
              <p>Comes with an Escrow based system</p>
            </div>
            <div className="revenue">
              <p>Large Customization Scope</p>
              <p>Huge Return on investment</p>
              <p>Top tier security</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BenifitsOf